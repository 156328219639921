import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import StoreContext from './../../context/StoreContext';
import SEO from '~/components/seo';
import ProductForm from '../../components/ProductForm/ProductForm';

import { Theme } from '@sashka/theme';
import { Typography } from '@sashka/ui-lib';
import ProductsGrid from './../../components/ProductsGrid/ProductsGrid';
import Image from 'gatsby-image';
import { getPrice } from '../../utils/currency';

export const Container = styled('section')<{ theme: Theme }>(({ theme }) => {
  return {
    // marginBottom: '1rem',
    width: '100%',
    background: theme.palette.background.site,
    marginBottom: '1.45rem',
    marginTop: '6rem',
    padding: `0 ${theme.gutters.base}px`,
  };
});

export const Grid = styled('section')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: '2.5rem',
  gridTemplateColumns: 'repeat(1, 1fr)',
  [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
}));

export const Product = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  //   minHeight: '100%',
  flexDirection: 'column',
}));

export const Img = styled(Image)<{ theme: Theme }>(({ theme }) => ({
  maxWidth: '100%',
  margin: 0,
  padding: 0,
}));

const CollectionPage = ({ data }) => {
  const {
    store: { checkout },
  } = React.useContext(StoreContext);

  const collection = data.shopifyCollection;
  return (
    <>
      <SEO title={collection.title} description={collection.description} />
      <Container>
        Collection Page
        <Typography {...{ variant: 'h1', component: 'h1' }}>{collection.title}</Typography>
        <Grid>
          {collection.products.map(({ id, handle, images: [firstImage], title, variants: [firstVariant] }) => {
            // console.log('CollectionPage -> product', product);
            return (
              <Product {...{ key: id }}>
                <Link {...{ to: `/product/${handle}/` }}>
                  <Img {...{ fluid: firstImage.localFile.childImageSharp.fluid, alt: title }} />
                  <Typography {...{ variant: 'h4', component: 'h2', center: true }}> {title}</Typography>
                  <Typography {...{ variant: 'body1', component: 'span', center: true }}>
                    {getPrice(firstVariant.price, checkout.currencyCode)}
                  </Typography>
                </Link>
              </Product>
            );
          })}
        </Grid>
      </Container>
    </>
  );
};

export const query = graphql`
  query($handle: String!) {
    shopifyCollection(handle: { eq: $handle }) {
      id
      title
      description
      products {
        id
        title
        handle
        variants {
          price
        }
        images {
          id
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 910) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

export default CollectionPage;
